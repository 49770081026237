import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { CustomerDetail } from '../modules/enrollment/models/data/account-detail.model';
import { BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import _ from 'lodash';
import { SelectedSubscription } from '../modules/enrollment/models/data/selected-subscription.model';
import { AppConfig } from '../config/app.config';
import { WindowReferenceService } from './window-reference.service';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public selectedAccountNumber$ = new BehaviorSubject<String>(null);
  public loadingAccounts$ = new BehaviorSubject<boolean>(true);
  public selectedNewKwAmount$ = new BehaviorSubject<number>(null);
  public enrollmentHasBeenCanceled$ = new BehaviorSubject<boolean>(null);
  
  constructor(public http: HttpClient, public api: ApiService, public appConfig: AppConfig, private windowRef: WindowReferenceService) {
  }

  setAccounts(accounts: any[]) { sessionStorage.setItem('accounts', JSON.stringify(accounts)); }
  setIsInRSPMode(isInRSP: boolean) { sessionStorage.setItem('isInRSP', isInRSP + ""); }
  getIsInRSPMode() {
    return sessionStorage.getItem('isInRSP') === "true";
  }
  setIsInEditMode(isInEdit: boolean) { sessionStorage.setItem('isInEdit', isInEdit + ""); }
  getIsInEditMode() {
    return sessionStorage.getItem('isInEdit') === "true";
  }
  setCurrentCalculatorSize(currentCalculatorSize: any) { sessionStorage.setItem('currentCalculatorSize', JSON.stringify(currentCalculatorSize)); }
  getCurrentCalculatorSize() {
    return JSON.parse(sessionStorage.getItem('currentCalculatorSize'));
  }
  setCustomerSegmentId(customerSegment: string) { sessionStorage.setItem('customerSegment', customerSegment); }
  getCustomerSegmentId() {
    return sessionStorage.getItem('customerSegment');
  }

  setRelatedAccounts(relatedAccounts: string[]) { sessionStorage.setItem('relatedAccounts', JSON.stringify(relatedAccounts)); }
  getRelatedAccounts() {
    return  JSON.parse(sessionStorage.getItem('relatedAccounts'));
  }

  setCDPId(cdpId: string) { sessionStorage.setItem('cdpId', cdpId); }
  getCDPId() {
    return sessionStorage.getItem('cdpId');
  }

  getAccounts() {
    return JSON.parse(sessionStorage.getItem('accounts'));
  }

  setSelectedAccountNumber(selectedAccountNumber: string) {
    const encryptedInput = this.encryptData(JSON.stringify(selectedAccountNumber));
    sessionStorage.setItem('selectedAccountNumber', encryptedInput);
    this.selectedAccountNumber$.next(selectedAccountNumber);
  }

  getSelectedAccountNumber() {
    const encryptedInput = sessionStorage.getItem('selectedAccountNumber');
    return encryptedInput ? this.decryptData(encryptedInput) : '';
  }

  getSelectedAccount() {
    const selectedAccountNumber = this.getSelectedAccountNumber();
    if (selectedAccountNumber) {
      const selectedAccount = (<any[]>this.getAccounts()).find(ta => {
        return ta.account_number == selectedAccountNumber
      });
      return selectedAccount || null;
    }
    return null;
  }

  setSelectedAllocation(selectedAllocation: any) {
    sessionStorage.setItem('selectedAllocation', JSON.stringify(selectedAllocation));
  }

  getSelectedAllocation() { return JSON.parse(sessionStorage.getItem('selectedAllocation')); }

  setSelectedSubscriptions(subscriptions: SelectedSubscription[]) {
    sessionStorage.setItem('selectedSubscriptions', JSON.stringify(subscriptions));
  }

  enrollAccount(newEnrollmentRequest: any) {
    return this.api.enrollAccount(newEnrollmentRequest).pipe(
      catchError(async (errors) => {
        let errorString = '';
        errors.forEach((error) => {
          errorString += error + ' ';
        });
        console.error('Error Enrolling Account: ' + errorString);
        throw errorString;
      })
    );
  }

  updateEnrollment(editEnrollmentRequest: any) {
    return this.api.editEnrollment(editEnrollmentRequest).pipe(
      catchError(async (errors) => {
        let errorString = '';
        errors.forEach((error) => {
          errorString += error + ' ';
        });
        this.windowRef.nativeWindow.dataLayer.push({ 
            'event-category':'cec_error', 
            'event-action': `Error Editing Enrollment: ' ${errorString}` ,
            'event-label':'enrollment-error', 'event':'event-click' });
        console.error('Error Editing Enrollment: ' + errorString);
        throw errorString;
      })
    );
  }

  cancelEnrollment(cancelEnrollmentRequest: any) {
    return this.api.cancelEnrollment(cancelEnrollmentRequest).pipe(
      catchError(async (errors) => {
        let errorString = '';
        errors.forEach((error) => {
          errorString += error + ' ';
        });
        console.error('Error Canceling Enrollment: ' + errorString);
        throw errorString;
      })
    );
  }

  getAlerts(accountNumber: string) {
    const customerAlertRequest = <any>{
      "input": {"account_id": accountNumber }
    }
    return this.api.getCustomerPortalAlerts(customerAlertRequest);
  }

  getMessages(accountNumber: string) {
    const customerAlertRequest = <any>{
      "input": {"account_id": accountNumber }
    }
    return this.api.getCustomerPortalAlerts(customerAlertRequest);
  }

  getSelectedSubscriptions() { return JSON.parse(sessionStorage.getItem('selectedSubscriptions')); }

  private encryptData(data: string): string {
    return CryptoJS.AES.encrypt(data, 'secret-key').toString();
  }
  
  private decryptData(data: string): string {
    const bytes = CryptoJS.AES.decrypt(data, 'secret-key');
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
