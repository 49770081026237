import { Injectable } from '@angular/core';
// import { ConsoleLogger } from '@aws-amplify/core';

// @ts-ignore
import {environment} from '../../environments/environment';
import {environments} from '../../config/env.config';

@Injectable()
export class AppConfig {
  private config: any;
  currentEnvironment = environment.EnvName;
  environmentConfigs = environments;
  constructor() {
  }

  public getConfig(key: any) {
    return this.config[key];
  }

  public load() {
    this.config = this.environmentConfigs[this.currentEnvironment];
  }
}
