import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import Amplify from "aws-amplify";

// get export file per environment
// import aws_exports_sbx    from "./environments/aws-exports-sbx";
// import aws_exports_prod   from "./environments/aws-exports-prod";
// import aws_exports_qa     from "./environments/aws-exports-qa";
// import aws_exports_tst    from "./environments/aws-exports-tst";
// import aws_exports_dev    from "./environments/aws-exports-dev";
// import aws_exports_local  from "./environments/aws-exports-local";

// if (environment.EnvName == "prod") {
//   enableProdMode();
//   Amplify.configure(aws_exports_prod);
// }
// else if(environment.EnvName == "qa"){
//   Amplify.configure(aws_exports_qa);
// }
// else if(environment.EnvName == "tst"){
//   Amplify.configure(aws_exports_tst);
// }
// else if(environment.EnvName == "dev"){
//   Amplify.configure(aws_exports_dev);
// }
// else if(environment.EnvName == "sbx"){
//   Amplify.configure(aws_exports_sbx);
// }
// else if(environment.EnvName == "local"){
//   Amplify.configure(aws_exports_local);
// }
// else{
//   Amplify.configure(aws_exports_sbx);
// }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
