// Angular imports...
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { EnrollmentModule } from './modules/enrollment/enrollment.module';
import { CommonModule } from '@angular/common';
import { EnrollmentRoutingModule } from './modules/enrollment/enrollment-routing.module';
import { CustomerPortalModule } from './modules/customer-portal/customer-portal.module';
import { CustomerPortalRoutingModule } from './modules/customer-portal/customer-portal-routing.module';

// Third-party...
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

// Duke Electron...
import { DeButtonModule,
      DeFooterModule,
      DeHeaderModule,
      DeLoaderModule,
      DeStepperModule,
      DeRadioButtonModule,
      DeLoginModule} from '@de-electron/electron-angular-components';
import { SharedModule } from './shared.module';
import { PipeModule } from './pipes/pipe.module';
import { AuthGuard } from './modules/enrollment/guards/auth.guard';
import { AuthInterceptor } from './modules/enrollment/interceptors/token.interceptor';
import { AuthenticationService, ApiService, StepperService } from './services';
import { AppConfig } from './config/app.config';

/* import AmplifyUIAngularModule  */
// import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { AdminModule } from './modules/admin/admin.module';
import { AdminRoutingModule } from './modules/admin/admin-routing.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    EnrollmentModule,
    CustomerPortalModule,
    SharedModule,
    AdminModule,
    AdminRoutingModule,
    EnrollmentRoutingModule,
    CustomerPortalRoutingModule,
    AppRoutingModule,
    HttpClientModule,
    MatCheckboxModule,
    MatExpansionModule,
    DeButtonModule,
    DeFooterModule,
    DeHeaderModule,
    DeStepperModule,
    DeRadioButtonModule,
    DeLoginModule,
    DeLoaderModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    MatFormFieldModule,
    PipeModule,
    MatTooltipModule,
    // AmplifyUIAngularModule,
    MatIconModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    AuthenticationService,
    ApiService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    StepperService,
    AuthGuard,
    [ AppConfig,
      {
        provide: APP_INITIALIZER,
        useFactory: getUseFactory,
        deps: [AppConfig],
        multi: true
      }],
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function getUseFactory(config: AppConfig) {
  return () => config.load();
}
